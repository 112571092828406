import React from "react";
import { Typography, Box, useTheme } from "@mui/material";

const Header1 = ({ title, subTitle }) => {
  const theme = useTheme();

  return (
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'start', backgroundColor: '#af2504', width: '100%' }}>
      <Typography
        variant="h2"
        color={theme.palette.secondary[100]}
        fontWeight="bold"
        sx={{ mx: "40px", my: "20px" }}
      >
        {title}
      </Typography>
      <Typography 
        variant="h3" 
        color={theme.palette.secondary[300]}        
        sx={{ mx: "40px", my: "20px" }}
      >
        {subTitle}
      </Typography>
    </Box>
  );
};

export default Header1;
