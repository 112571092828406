import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useGetUserQuery, generalApi } from "state/api";
import Header from "components/Header";
import {  Typography } from "@material-ui/core";
import { Box, useTheme, Button, useMediaQuery } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { refundTableColumns } from "utilities/CommonUtility";
import DataGridCustomToolbar from 'components/DataGridCustomToolbar';
import { AuthContext } from "../../context/AuthContext";
import { useNavigate  } from "react-router-dom";
import Swal from 'sweetalert2';

import {
  Bottom
} from "scenes";

// styles
import useStyles from "../../assets/dashboard_styles";
// logo
import logo from "../../assets/alp_logo.jpg";

const Profile = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  // values to send to backend
  
  const { getAuthUser} = useContext(AuthContext);
  const authUser = getAuthUser();
  const navigate = useNavigate();


  useEffect(() => {   
    if (authUser === null) {
      // navigate('/home');
    }
  }, [])

  return (
    <Box m="0.5rem 1rem" display={"flex"} justifyContent={"center"} flexDirection={"column"}>
      <Box
        // mt="20px"
        display="flex"
        justifyContent={"space-around"}
        width={"100%"}
        minWidth={"400px"}
        // gridTemplateColumns="repeat(12, 1fr)"
        // gridAutoRows="160px"
        borderRadius="0.55rem"
        p="1rem"
        backgroundColor={theme.palette.background.alt}            
      >
        <Box
          display="flex"
          // flexGrow={0.3}
          width={'100%'}
          minWidth={'400px'}
          justifyContent={"center"}
        >                        
          <img src={logo} alt="logo" className={classes.logotypeImageAlp} />
        </Box>
      </Box>
      <Box
        mt="20px"
        display="flex"
        justifyContent={"space-around"}
        width={"100%"}
        minWidth={"400px"}
        // gridTemplateColumns="repeat(12, 1fr)"
        // gridAutoRows="160px"
        borderRadius="0.55rem"
        p="1rem"
        backgroundColor={theme.palette.background.alt}            
      >
        <Bottom path="about" />
      </Box>
    </Box> 
  );
};

export default Profile;
