import React, { useMemo, useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate  } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { Box, useTheme, useMediaQuery, Button } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Swal from 'sweetalert2';
import {  
  Typography,
} from "@material-ui/core";
import {
  Bottom
} from "scenes";
import axios from 'axios';

// styles
import useStyles from "../../assets/dashboard_styles";
// logo
import logo from "../../assets/main-image.png";

const Products = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const { getAuthUser} = useContext(AuthContext);
  const [files, setFiles] = useState([]);
  
  let authUser = getAuthUser();
  const navigate = useNavigate();
  
  const [language, setLanguage] = useState('');

  useEffect(() => {
    axios.get('/api/general/files')
      .then(response => {
        setFiles(response.data.files);
      })
      .catch(error => {
        console.error('Error fetching files:', error);
      });
  }, []);

  const handleDownload = (filename) => {
    window.open(`/api/general/download/${filename}`, '_blank');
  };
  
  useEffect(() => {
    if (authUser === null) {
      // navigate('/home');
      // alert('Not logged in')
    }
  }, [authUser])

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  
  const handleDownload0 = (event) => {
    event.preventDefault();
    // console.log(language);
    let url = 'https://deusexmundo.com/downloads/'; // Replace with your file URL
    const file_name = (language === '')?'DeusExMundo.exe':`DeusExMundo_${language}.exe`;
    url += file_name;

    // Create an invisible link element
    const link = document.createElement('a');
    link.href = url;
    link.download = file_name; // Set the download file name
    document.body.appendChild(link);

    // Trigger the download by simulating a click on the link
    link.click();

    // Clean up by removing the link from the DOM
    document.body.removeChild(link);

  }

  return (
    
  <Box m="0.5rem 1rem" display={"flex"} justifyContent={"center"} flexDirection={"column"}>
    <Box
      // mt="20px"
      display="flex"
      justifyContent={"space-around"}
      width={"100%"}
      minWidth={"400px"}
      // gridTemplateColumns="repeat(12, 1fr)"
      // gridAutoRows="160px"
      borderRadius="0.55rem"
      p="1rem"
      backgroundColor={theme.palette.background.alt}            
    >
      <Box
        display="flex"
        // flexGrow={0.3}
        width={'30%'}
        minWidth={'350px'}
        justifyContent={"center"}
      >
        <Box m="0.5rem 0.5rem" width={"100%"}>
          <Box
            // mt="20px"
            display="flex"
            justifyContent={"center"}
            // p="1rem"
            backgroundColor={theme.palette.background.alt}
          >
            <Box
              // gridColumn="span 12"
              // gridRow="span 6"
              width={'100%'}
              
              // p="1rem"
              borderRadius="0.55rem"
            >
			  <Box
                p="1rem"
              >
			    <Typography variant="h5" sx={{ color: theme.palette.secondary[100] }} style={{paddingLeft: "1rem", marginBottom: "2rem" }}>List of Downloads</Typography>
			    <ul style={{ padding: "0" }}>
				  {files.map((filename, index) => (
				    <Box
					  display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gridAutoRows="60px" 
					  style={{ alignItems: 'center' }}
					  key={index}
					>					  
					  <Box 
						width="100%"
                        gridColumn="span 9"
                        gridRow="span 1"
					    style={{ fontSize: "15px" }}
					  >
					    {index+1 + '. ' + filename}
					  </Box>
					  <Box
					    width="100%"
                        gridColumn="span 3"
                        gridRow="span 1"
					  >
						<Button variant="contained" onClick={() => handleDownload(filename)}>
						  Download
						</Button>
					  </Box>
				    </Box>
				  ))}
			    </ul>
			  </Box>
              {/*<Typography variant="h6" sx={{ color: theme.palette.secondary[100] }} style={{paddingLeft: "1rem"}}>
                Downloads
              </Typography>
              <Box
                p="1rem"
              >
                <Typography style={{ fontSize: "13px" }}>Please select your preferred language.</Typography>
                <Box
                  py="1rem"
                >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Language </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={language}
                      label="Language"
                      onChange={handleLanguageChange}
                    >
                      <MenuItem value={'en'}>English</MenuItem>
                      <MenuItem value={'fr'}>French</MenuItem>
                      <MenuItem value={'de'}>Deutsch</MenuItem>
                    </Select>
                  </FormControl>
                </Box>                
                <Button id="submit" variant="contained" onClick={handleDownload0}>Download</Button>
              </Box> 
              <Box
                p="1rem"
              >
                <Typography variant="h7" >Other downloads</Typography>
                <Box
                  p="0.3rem 1rem"
                  display={"flex"}
                >
                  <Typography style={{ fontSize: "13px" }} >User manual: </Typography>
                  <a href="/downloads/alp-game-manual.pdf" target="_blank" style={{color: "yellow", marginLeft: "0.3rem"}}> alp-game-manual.pdf</a>
                </Box>
                <Box
                  p="0.3rem 1rem"
                  display={"flex"}
                >
                  <Typography style={{ fontSize: "13px" }} >Sticker: </Typography>
                  <a href="/downloads/sticker.png" target="_blank" style={{color: "yellow", marginLeft: "0.3rem"}}> sticker.png</a>
                </Box>
              </Box> */}       
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display={isNonMobile ?"flex": "none"}
        // flexGrow={1}
        width={'70%'}
        justifyContent={"center"}
        alignItems={"center"}
      >              
        <img src={logo} alt="logo" className={classes.logotypeImage} />
      </Box>
    </Box>
    <Box
      mt="20px"
      display="flex"
      justifyContent={"space-around"}
      width={"100%"}
      minWidth={"400px"}
      // gridTemplateColumns="repeat(12, 1fr)"
      // gridAutoRows="160px"
      borderRadius="0.55rem"
      p="1rem"
      backgroundColor={theme.palette.background.alt}            
    >
      <Bottom path="downloads" />
    </Box>
  </Box>        
  );
};

export default Products;