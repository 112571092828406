export { default as Home } from "./home/Home";
export { default as PasswordRecovery } from "./home/PasswordRecovery";
export { default as Dashboard } from "./dashboard/Dashboard";
export { default as Layout } from "./layout/Layout";
export { default as Products } from "./products/Products";
export { default as Product } from "./products/product/Product";
export { default as Merchants } from "./merchants/Merchants";
export { default as Players } from "./players/Players";
export { default as MassMails } from "./massmails/MassMails";
export { default as Transactions } from "./transactions/Transactions";
export { default as Profile } from "./profile/Profile";
export { default as About } from "./about/About";
export { default as Geography } from "./geography/Geography";
export { default as Overview } from "./overview/Overview";
export { default as Daily } from "./daily/Daily";
export { default as Monthly } from "./monthly/Monthly";
export { default as Breakdown } from "./breakdown/Breakdown";
export { default as Admin } from "./admin/Admin";
export { default as Performance } from "./performance/Performance";
export { default as Pointofsale } from "./pointofsale/Pointofsale";
export { default as Login } from "./login/Login";
export { default as PageNotFound } from "./pagenotfound/PageNotFound";
export { default as Bottom } from "./bottom/Bottom";

