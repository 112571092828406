import React, { useMemo, useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate  } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import { ResponsiveLine } from "@nivo/line";
import DatePicker from "react-datepicker";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import {
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import BreakdownChart from "components/BreakdownChart";
import OverviewChart from "components/OverviewChart";
import { useGetDashboardQuery, useGetChartQuery } from "state/api";
import StatBox from "components/StatBox";
import Sidebar from "components/Sidebar";
import { useGetUserQuery, generalApi } from "state/api";
import Swal from 'sweetalert2';
import YouTube from 'react-youtube';

// styles
import useStyles from "../../assets/bottom_styles";
// logo
import image3 from "../../assets/_image03.jpeg";
import image4 from "../../assets/_image04.jpeg";
import image6 from "../../assets/_image06.jpeg";
import image7 from "../../assets/_image07.jpeg";

const Bottom = ({path}) => {
  
  const classes = useStyles();
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  return (
    <Box width={"100%"}>
	  <Box
        // mt="20px"
        display="flex"
        justifyContent={"center"}
        // p="1rem"
        backgroundColor={theme.palette.background.alt}
      >
	    <YouTube videoId="0AN2iX3uavk" />
	  </Box>
      <Box
        // mt="20px"
        display="flex"
        justifyContent={"center"}
        // p="1rem"
        backgroundColor={theme.palette.background.alt}
      >
        { (path === 'dashboard' || path === 'downloads') &&
          <Box
            // gridColumn="span 12"
            // gridRow="span 6"
            width={'100%'}
            display="flex"
            flexDirection={'column'}
            p="1rem"
            borderRadius="0.55rem"
          >
            <Header title="Highlights" /> 
            <Box
              width={'100%'}          
              py="1rem"
              display="flex"
              flexDirection={'row'}
              alignItems={'start'}
            >
              <img src={image6} className={classes.logotypeImage} />
              <Typography>You will be taking on the role of the almighty leader of your country, your word is the rule of law in your domain! Military, scientific research, even residential needs are all in your hands.</Typography>
            </Box>
            <Box
              width={'100%'}          
              py="1rem"
              display="flex"
              flexDirection={'row'}
              alignItems={'start'}
            >
              <img src={image7} className={classes.logotypeImage} />
              <Typography>The largest map is 800 regions or sectors to battle for.</Typography>
            </Box>
          </Box>
        }
        {
          (path === 'contacts') &&
          <Box
            // gridColumn="span 12"
            // gridRow="span 6"
            width={'100%'}
            display="flex"
            flexDirection={'column'}
            p="1rem"
            borderRadius="0.55rem"
          >
            <Header title="Contacts" />
            <Box
              width={'100%'}          
              py="1rem"
              display="flex"
              flexDirection={'column'}
              alignItems={'start'}
            >                           
              <Typography>Phone: +33 786217443</Typography>
              <Typography>Email: support@deusexmundo.com</Typography>
            </Box>
            <Header title="Social links" />
            <Box
              width={'100%'}          
              py="1rem"
              display="flex"
              flexDirection={'column'}
              alignItems={'start'}
            >                           
              <Typography>Facebook: <a href="https://www.facebook.com/DeusExMundo" style={{color: 'white'}} target="_blank">DeusExMundo Official Page</a></Typography>
              <Typography>Youtube: <a href="https://www.youtube.com/channel/UCX_ZUpzaNHqtbZnDjvelnkg" style={{color: 'white'}} target="_blank">DeusExMundo Video Chanel</a></Typography>
            </Box>
          </Box>
        }
        {
          (path === 'about') &&
          <Box
            // gridColumn="span 12"
            // gridRow="span 6"
            width={'100%'}
            display="flex"
            flexDirection={'column'}
            p="1rem"
            borderRadius="0.55rem"
          >
            <Header title="About us" />
            <Box
              width={'100%'}          
              py="1rem"
              display="flex"
              flexDirection={'column'}
              alignItems={'start'}
            >                           
              <Typography>We at ALP Are a small team working to make epic games that are breathtaking and exciting to play day in day out. 
                We strive to make the best games you can find.</Typography>
            </Box>
          </Box>
        }
        { (path === 'about') ?
          <Box
            // gridColumn="span 12"
            // gridRow="span 6"
            width={'100%'}
            display="flex"
            flexDirection={'column'}
            p="1rem"
            borderRadius="0.55rem"
          >
            <Header title="Photos" /> 
            <Box
              width={'100%'}          
              py="1rem"
              display="flex"
              flexDirection={'row'}
              alignItems={'start'}
            >
              <img src={image3} className={classes.logotypeImage} />
              <img src={image4} className={classes.logotypeImage} />
            </Box>
            <Box
              width={'100%'}          
              py="1rem"
              display="flex"
              flexDirection={'row'}
              alignItems={'start'}
            >
              <img src={image6} className={classes.logotypeImage} />              
              <img src={image7} className={classes.logotypeImage} />
            </Box>      
          </Box> :
          <Box
            // gridColumn="span 12"
            // gridRow="span 6"
            width={'100%'}
            display="flex"
            flexDirection={'column'}
            p="1rem"
            borderRadius="0.55rem"
          >
            <Header title="Play Now" /> 
            <Box
              width={'100%'}          
              py="1rem"
              display="flex"
              flexDirection={'row'}
              alignItems={'start'}
            >
              <img src={image3} className={classes.logotypeImage} />
              <Box
                display="flex"
                flexDirection={'column'}
              >
                <a href="/downloads" style={{color: "red"}}>Download now!</a>
                <Typography>60+ different buildings, 45+ different battle units, and so many other things...</Typography>
              </Box>            
            </Box> 
            <Box
              width={'100%'}          
              py="1rem"
              display="flex"
              flexDirection={'row'}
              alignItems={'start'}
            >
              <img src={image4} className={classes.logotypeImage} />
              <Box
                display="flex"  
                flexDirection={'column'}
              >
                <a href="/downloads" style={{color: "red"}}>Become the One of the world!</a>
                <Typography>If you want a game that you can't mop up in 5 minutes, this is the right game for you.</Typography>
              </Box>            
            </Box>      
          </Box>
        }
      </Box>
    </Box>
  );
};

export default Bottom;