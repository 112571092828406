import React, { useState, useContext, useEffect } from "react";
import { useNavigate  } from "react-router-dom";
import { Box, useMediaQuery, useTheme, Typography } from "@mui/material";
import Header1 from "components/Header1";
import Header from "components/Header";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
import { useGetUserQuery } from "state/api";
import { AuthContext } from "../../context/AuthContext";

// styles
import useStyles from "../../assets/layout_styles";

const Layout = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const theme = useTheme();
  const classes = useStyles();
  // const userId = useSelector((state) => state.global.userId);
  
  // const { data } = useGetUserQuery(userId);
  
  const navigate = useNavigate();
  const { getAuthUser, isAuthenticated } = useContext(AuthContext);
  const authUser = getAuthUser();
  const _isAuthenticated = isAuthenticated();
  // console.log('isAuthenticated', _isAuthenticated);

  useEffect(() => {
    if (authUser === null || !_isAuthenticated) {
      // navigate('/login');
    }
  }, [authUser, _isAuthenticated])

  // console.log(data);

  return (
    <Box display={isNonMobile ? "flex" : "flex"} width="80%" position={"absolute"}  flexDirection={"column"} justifyContent={"center"} alignItems={"center"} backgroundColor={"#060b2b"} minWidth={'450px'}>
      <Box  display="flex" justifyContent={"center"} flexDirection={"column"} alignItems={"center"} width={'100%'}>
        <Header1 title="Deus Ex Mundo" subTitle="Real time Strategy game of the year"/>
        <Navbar
          user={authUser || {}}
        />
        <Outlet />    
      </Box>
      <Box  display="flex" justifyContent={"center"} flexDirection={"column"} alignItems={"center"} width={'100%'} height={'40px'}>
        <Typography color='#af2504' className={classes.copyright}>
          © 2024 Deus Ex Mundo. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Layout;
