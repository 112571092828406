import { CssBaseline, createTheme, ThemeProvider } from "@mui/material";
import { useMemo, useContext } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import { AuthProvider } from "./context/AuthContext";
// context
import { AuthContext } from "./context/AuthContext";
import {
  Dashboard,
  Home,
  PasswordRecovery,
  Layout,
  Products,
  Merchants,
  Transactions,
  Geography,
  Overview,
  Daily,
  Monthly,
  Breakdown,
  Admin,
  Performance,
  Pointofsale,
  Players,
  MassMails,
  Profile,
  About,
  Login,
  PageNotFound
} from "scenes";

function App() {
  
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app" style={{display: 'flex', justifyContent: 'center', backgroundColor: '#4c5067'}}>
      <BrowserRouter>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route element={<Layout />}>
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route path="/home" element={<Home />} />
                <Route path="/passwordrecovery" element={<PasswordRecovery />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/downloads" element={<Products />} />
                <Route path="/contacts" element={<Profile />} />
                <Route path="/about" element={<About />} />
                <Route path="/users" element={<Players />} />
                <Route path="/massmails" element={<MassMails />} />
                <Route path="/deposit" element={<Pointofsale />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/geography" element={<Geography />} />
                <Route path="/overview" element={<Overview />} />
                <Route path="/daily" element={<Daily />} />
                <Route path="/monthly" element={<Monthly />} />
                <Route path="/breakdown" element={<Breakdown />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/performance" element={<Performance />} />
              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </ThemeProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
