import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";

const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-type": "application/json"
  }
});

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Customers",
    "Products",
    "Transactions",
    "Refunds",
    "Geography",
    "Sales",
    "Admins",
    "Performance",
    "Dashboard"
  ],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `general/user/${id}`,
      providesTags: ["User"],
    }),
    getProducts: build.query({
      query: () => `client/products`,
      providesTags: ["Products"],
    }),
    getMerchants: build.query({
      query: () => `client/merchants/`,
      providesTags: ["Merchants"],
    }),
    getPlayers: build.query({
      query: () => `client/players/`,
      providesTags: ["Players"],
    }),
    getMassMails: build.query({
      query: () => `general/getMassMails/`,
      providesTags: ["MassMails"],
    }),
    getTransactions: build.query({
      query: ({ id, startDate, endDate, page, pageSize, sort, search }) => ({
        url: `client/transactions`,
        method: "GET",
        params: { id: JSON.stringify(id), startDate, endDate, page, pageSize, sort, search },
      }),
      tagTypes: ["Transactions"],
    }),
    getRefunds: build.query({
      query: ({ page, pageSize, sort, search }) => ({
        url: `client/refunds`,
        method: "GET",
        params: { page, pageSize, sort, search },
      }),
      tagTypes: ["Refunds"],
    }),
    getGeoLocations: build.query({
      query: () => `client/geography`,
      providesTags: ["Geography"],
    }),
    getOverallSales: build.query({
      query: () => `sales/sales`,
      providesTags: ["Sales"],
    }),
    getAdmins: build.query({
      query: () => `management/admin`,
      providesTags: ["Admins"],
    }),
    getUserPerformance: build.query({
      query: (id) => `management/performance/${id}`,
      providesTags: ["Performance"],
    }),
    getDashboard: build.query({
      query: ({id, startDate, endDate}) => ({
        url: `general/dashboard`,
        method: "GET",
        params: { id: JSON.stringify(id), startDate, endDate },
      }),
      tagTypes: ["Dashboard"],
    }),
    getSetting: build.query({
      query: () => ({
        url: `general/setting`,
        method: "GET",
      }),
      tagTypes: ["Setting"],
    }),
    getChart: build.query({
      query: ({id, startDate, endDate}) => ({
        url: `general/chart`,
        method: "GET",
        params: { id: JSON.stringify(id), startDate, endDate },
      }),
      tagTypes: ["Chart"],
    }),
    getPie: build.query({
      query: ({id, startDate, endDate}) => ({
        url: `general/pie`,
        method: "GET",
        params: { id: JSON.stringify(id), startDate, endDate },
      }),
      tagTypes: ["Pie"],
    })
  }),
});

export const authApi = {
  auth(url = 'auth') {
    return {
        login: ({email, password}) => http.post(url + '/login', {email, password}),
        register: ({email, name, password, recaptchaValue}) => http.post(url + '/register', {email, name, password, recaptchaValue})
    }
  },

  map(url = 'map') {
      const config = {
        headers: {
          'authorization': 'Bearer ' + localStorage.getItem('token')
        }
      };

      return {
          fetchAll: () => http.get(url + '/list', config),
          fetchPagination: (page, limit, name, category) => 
              http.get(url + "?page=" + page + "&limit=" + limit + "&name=" + name + "&category=" + category, config),
          fetchById: id => http.get(url + "/" + id, config),
          create: newRecord => http.post(url, newRecord, config),
          update: (id, updatedRecord) => http.put(url + "/" + id, updatedRecord, config),
          delete: id => http.delete(url + "/" + id, config)
      }
  },

  user(url = 'user') {
      const config = {
        headers: {
          'authorization': 'Bearer ' + localStorage.getItem('token')
        }
      };

      return {
          fetchAll: () => http.get(url + '/list', config),
          fetchPagination: (page, limit = 10, name = null, email = null) => 
              http.get(url + "?page=" + page + "&limit=" + limit + "&name=" + name + "&email=" + email, config),
          fetchById: id => http.get(url + "/" + id, config),
          create: newRecord => http.post(url, newRecord, config),
          update: (id, updatedRecord) => http.put(url + "/" + id, updatedRecord, config),
          delete: id => http.delete(url + "/" + id, config)
      }
  }

};

export const generalApi = {
  general(url = 'general') {
    return {
        getUser: (id) => http.get(url + `/getUser/${id}`).then(res => res.data),
        addUser: ({name, email, password, type, currency, licenseKey}) => http.post(url + `/addUser`, {name, email, password, type, currency, licenseKey}),
        updateUser: ({id, licenseKey, status}) => http.post(url + `/updateUser`, {id, licenseKey, status}),
        updatePassword: ({id, oldP, newP}) => http.post(url + `/updatePassword`, {id, oldP, newP}),
        deleteUser: (id) => http.post(url + `/deleteUser`, {id}),
        updatePurchase: ({id, status}) => http.post(url + `/updatePurchase`, {id, status}),
        updatePrice: ({id, price}) => http.post(url + `/updatePrice`, {id, price}),
        updatePaymentStatus: ({id, paymentStatus}) => http.post(url + `/updatePaymentStatus`, {id, paymentStatus}),        
        deleteTransaction: (id) => http.post(url + `/deleteTransaction`, {id}),
        refundTransaction: (mid, licenseKey, orderId, amount) => http.post(`payment/refund2d`, {mid, orderId, amount}, {headers: {"x-api-key" :licenseKey}}),
        addUserMessage: ({name, email, phone, message, recaptchaValue}) => http.post(url + `/addUserMessage`, {name, email, phone, message, recaptchaValue}),
        sendMassMails: ({subject, text}) => http.post(url + `/sendMassMails`, {subject, text}),
        deleteMassMail: (id) => http.post(url + `/deleteMassMail`, {id}),
        sendEmailVerifyCode: ({email}) => http.post(url + `/sendEmailVerifyCode`, {email}),
        checkVerifyCode: ({verifyCode, encryptCode}) => http.post(url + `/checkVerifyCode`, {verifyCode, encryptCode}),        
        resetPassword: ({email, password}) => http.post(url + `/resetPassword`, {email, password}),
        

    }
  },

};

export const paymentApi = {
  payment(url = 'payment') {
    return {
        // payment2d: (headers, payload) => http.post(url + `/2d`, payload, { headers }),
        payment2d: (headers, payload) => http.post(url + `/charge`, payload, { headers }),
        
    }
  },

};

export const {
  useGetUserQuery,
  useGetProductsQuery,
  useGetMerchantsQuery,
  useGetPlayersQuery,
  useGetMassMailsQuery,
  useGetTransactionsQuery,
  useGetRefundsQuery,
  useGetSettingQuery,
  useGetGeoLocationsQuery,
  useGetOverallSalesQuery,
  useGetAdminsQuery,
  useGetUserPerformanceQuery,
  useGetDashboardQuery,
  useGetChartQuery,
  useGetPieQuery
} = api;
