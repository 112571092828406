import React, { useState, useEffect, useContext, useRef } from "react";
import { useGetTransactionsQuery, generalApi } from "state/api";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import DatePicker from "react-datepicker";
import { Box, useTheme, Button, Typography } from "@mui/material";
import Switch from '@mui/material/Switch';
// import { transactionTableColumns } from "utilities/CommonUtility";
import DataGridCustomToolbar from 'components/DataGridCustomToolbar';
import { AuthContext } from "../../context/AuthContext";
import { useNavigate  } from "react-router-dom";
import Swal from 'sweetalert2';
import { ConstructionOutlined } from "@mui/icons-material";
import Detail from './Detail';

import { DataGridPro, GridToolbar, GridToolbarContainer } from "@mui/x-data-grid-pro";
import { LicenseInfo } from '@mui/x-license-pro';

import axios from "axios";

LicenseInfo.setLicenseKey('3beb4fd4938a341fb6f47fe77d622867Tz03NDQ5MixFPTE3MjYxNTU5NDUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='); // expiry 2024/09/11

const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-type": "application/json"
  }
});

function CustomToolbar(props) {
  const { searchInput, setSearchInput, handleSearch } = props;

  return (
    <GridToolbarContainer>
      <FlexBetween style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
        <GridToolbar />
        <div>
          <input            
            type="text"
            autoFocus="autoFocus"
            value={searchInput}
            onKeyDown={(e) => {                        
              if (e.key === 'Enter') { // || e.key === ' '
                handleSearch();
                //  e.preventDefault();
              }
          }} 
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Search..."
          />
          <button onClick={handleSearch}>Search</button>
        </div>
      </FlexBetween>
    </GridToolbarContainer>
  );
}

const Transactions = () => {
  const theme = useTheme();
  const searchInputRef = useRef(null); 
  // values to send to backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [isDetail, setIsDetail] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  // Current date
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');  
  const curDate = `${year}-${month}-${day}`;

  currentDate.setMonth(currentDate.getMonth() - 1);
  const pyear = currentDate.getFullYear();
  const pmonth = String(currentDate.getMonth() + 1).padStart(2, '0');
  const pday = String(currentDate.getDate()).padStart(2, '0');

  const preDate = `${pyear}-${pmonth}-${pday}`;
  const [startDate, setStartDate] = useState(new Date(preDate));
  const [endDate, setEndDate] = useState(new Date(curDate));

  const [isTest, setIsTest] = useState(false);

  const { getAuthUser} = useContext(AuthContext);
  const authUser = getAuthUser();
  const navigate = useNavigate();

  const dateToString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 as month is zero-based
    const day = String(date.getDate()).padStart(2, '0');

    // Format the date string
    const formattedDate = `${year}-${month}-${day}`;
    // console.log(formattedDate);
    
    return formattedDate;
  }

  useEffect(() => {
    if (authUser === null) {
      navigate('/login');
    }
  }, [authUser])
// console.log('user', authUser);

  const transactionTableColumns = [
    {
      field: "merchantId",
      headerName: "Merchant ID",
      flex: 0.7,
    },
    // {
    //   field: "mode",
    //   headerName: "Mode",
    //   flex: 0.5,
    // },
    {
      field: "transactionType",
      headerName: "Type",
      flex: 0.5,
    },
    {
      field: "transactionId",
      headerName: "Txn ID",
      flex: 0.7,
    },
    {
      field: "orderId",
      headerName: "Order ID",
      flex: 1,
    },
    // {
    //   field: "orderDetail",
    //   headerName: "Order Detail",
    //   flex: 1,
    // },
    // {
    //   field: "paymentMethod",
    //   headerName: "Solution",
    //   flex: 0.5,
    // },
    // {
    //   field: "name",
    //   headerName: "Name",
    //   flex: 1,
    // },
    {
      field: "cardNumber",
      headerName: "Card Number",
      flex: 1,
    },
    // {
    //   field: "cardType",
    //   headerName: "Card Type",
    //   flex: 0.5,
    // },
    // {
    //   field: "country",
    //   headerName: "Country",
    //   flex: 1,
    // },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.5,
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
    },
    // {
    //   field: "response",
    //   headerName: "Response",
    //   flex: 1,
    // },
    {
      field: "createdAt",
      headerName: "Create Date",
      flex: 1,
    },
    // {
    //   field: "statusDate",
    //   headerName: "Status Date",
    //   flex: 1,
    // },
  ];

  if (authUser?.role === "admin" || authUser?.role === "superadmin") {
    transactionTableColumns.push(
      { 
        field: 'action',
        headerName: 'Action',
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <>
            <Button id="view" style={{marginRight: '10px'}} variant="contained" onClick={() => handleView(params.row)}>Detail</Button>
            <Button id="delete" variant="contained" onClick={() => handleDelete(params.row)}>Delete</Button>
            {
              (params.row.transactionType.startsWith("2D") && params.row.paymentMethod === 'MPS' && params.row.status === 'approved') &&
              <Button id="refund" variant="contained" onClick={() => handleRefund(params.row)}>Refund</Button>
            }
          </>          
        )
      }
    );
  } else {
    transactionTableColumns.push(
      { 
        field: 'action',
        headerName: 'Action',
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <>
            <Button id="view" variant="contained" onClick={() => handleView(params.row)}>Detail</Button>
            {
              (params.row.transactionType.startsWith("2D") && params.row.paymentMethod === 'MPS' && params.row.status === 'approved') &&
              <Button id="refund" variant="contained" onClick={() => handleRefund(params.row)}>Refund</Button>
            }
          </>          
        )
      }
    );
  }
  
  // const { data, isLoading, refetch } = useGetTransactionsQuery({
  //   id: authUser, 
  //   startDate: dateToString(startDate), 
  //   endDate: dateToString(endDate),
  //   page,
  //   pageSize,
  //   sort: JSON.stringify(sort),
  //   search,
  // });

  const fetchTransactions = () => {
    setLoading(true); // console.log('start-fetch', loading);
    http
    .get(
      `/client/transactions`, {
      params: { 
        id: JSON.stringify(authUser), 
        startDate: dateToString(startDate), 
        endDate: dateToString(endDate), 
        page, 
        pageSize, 
        sort: JSON.stringify(sort), 
        search,
        mode: isTest?"test":"live"
      }
    })
    .then(res => setData(res.data))
    .catch(err => console.log(err.log))
    .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchTransactions();
  }, [page, pageSize, search, isTest, startDate, endDate]);

  useEffect(() => {
    if (!isDetail) {
      fetchTransactions();
    }
  }, [isDetail]);

  const handlePaginationModelChange = (newModel) => {
    // console.log(newModel);
    setPage(newModel.page);
    setPageSize(newModel.pageSize);
  }

  const handleView = async row => {
   
    setSelectedRow(row);
    setIsDetail(true);
    // Swal.fire({
    //   icon: 'success',
    //   title: 'Transaction Detail',
    //   text: `${row.merchantId}'s transaction ${row.transactionId} has been ${row.status}.`,
    //   showConfirmButton: true,
    // });
   
  };

  const handleRefund = row => {
    let mid, licenseKey = "";
    if (authUser?.role === "admin" || authUser?.role === "superadmin") {
      mid = "merchant1";
      licenseKey = "0701050dde1b146e99fb3705fef896bb217b6c40cc87b5ea8f670d26d7d91c52";
    } else {
      mid = authUser.name;
      licenseKey = authUser.licenseKey;
    }

    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: 'Yes, refund it!',
      cancelButtonText: 'No, cancel!',
    }).then(result => {
      if (result.value) {
        // const [employee] = employees.filter(employee => employee.id === id);
        generalApi.general().refundTransaction(mid, licenseKey, row.orderId, row.amount)
        .then(res => {
          
          if (res.data.status === "refunded") {
            Swal.fire({
              icon: 'success',
              title: 'Refunded!',
              text: `${row.orderId}'s data has been refunded.`,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            console.log(res.data.status);
            
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Failed to refund.',
              showConfirmButton: true,
            });
          }

          fetchTransactions();
          
        })
        .catch(err => {
    
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Failed to refund.',
            showConfirmButton: true,
          });

          fetchTransactions();
        });

      }
    });
  }

  const handleDelete = row => {
    // setSelectedRow(row);

    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(result => {
      if (result.value) {
        // const [employee] = employees.filter(employee => employee.id === id);
        generalApi.general().deleteTransaction(row._id)
        .then(res => {
          
          if (res.data.success) {
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: `${row.transactionId}'s data has been deleted.`,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            console.log(res.data.error);
            
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Failed to delete.',
              showConfirmButton: true,
            });
          }

          fetchTransactions();
          
        })
        .catch(err => {
    
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Failed to delete.',
            showConfirmButton: true,
          });

          fetchTransactions();
        });

      }
    });
  };

  const handleSearch = () => {
    // Implement your search logic here
    setSearch(searchInput);
    // fetchTransactions();
  };

  const handleSwitchChange = () => {
    setIsTest(!isTest);
  };

  return (
    <Box m="1.5rem 2.5rem">
      {!isDetail && (
        <>
          <FlexBetween>
            <Header title="TRANSACTION" subTitle="Entire list of transactions" />
            <div style={{display:'flex', flexDirection: 'row', alignItems: 'center'}}>
              <Typography style={{marginRight: '5px'}}>LIVE</Typography>
              <Switch checked={isTest} onChange={handleSwitchChange} />
              <Typography style={{marginLeft: '5px'}}>TEST</Typography>
            </div>
            <Box>
              <Box>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                />
              </Box>
              <Box>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />
              </Box>
            </Box>
          </FlexBetween>      
          <Box
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <DataGridPro
              sx={{
                "& .css-zkx8c-MuiCircularProgress-root": {
                  color: theme.palette.secondary[100],
                }
              }}
              loading={loading}
              getRowId={(row) => row._id}
              rows={(data && data.transactions) || []}
              columns={transactionTableColumns}
              rowCount={(data && data.total) || 0}
              pagination
              paginationMode={"server"}
              onPaginationModelChange={handlePaginationModelChange}
              pageSizeOptions={[20,100,1000,10000,20000]}
              paginationModel={{page: page, pageSize: pageSize}}
              onSortModelChange={(newSortModel) => setSort(newSortModel)}
              // slots={{ toolbar: GridToolbar }}   
              components={{
                Toolbar: (props) => (
                  <CustomToolbar 
                    {...props} 
                    // searchInputRef={searchInputRef}
                    searchInput={searchInput} 
                    setSearchInput={setSearchInput} 
                    handleSearch={handleSearch} 
                  />
                ),
              }}            
            />
          </Box>
        </>          
      )}    
      {isDetail && (
        <>
          <Header title="TRANSACTION" subTitle="Details of a transaction" />
          <Detail
            selectedRow={selectedRow}
            setIsDetail={setIsDetail}
          />
        </>
      )}
    </Box>
  );
};

export default Transactions;
