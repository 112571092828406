import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useGetUserQuery, generalApi } from "state/api";
import Header from "components/Header";
import {  Typography } from "@material-ui/core";
import { Box, useTheme, Button, useMediaQuery } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { refundTableColumns } from "utilities/CommonUtility";
import DataGridCustomToolbar from 'components/DataGridCustomToolbar';
import { AuthContext } from "../../context/AuthContext";
import { useNavigate  } from "react-router-dom";
import Swal from 'sweetalert2';

import ReCAPTCHA from "react-google-recaptcha";

import {
  Bottom
} from "scenes";

// styles
import useStyles from "../../assets/dashboard_styles";
// logo
import logo from "../../assets/main-image.png";

const Profile = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  // values to send to backend
  
  const { getAuthUser} = useContext(AuthContext);
  const authUser = getAuthUser();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [messageError, setMessageError] = useState("");

  const recaptchaRef = React.createRef();

  useEffect(() => {   
    if (authUser === null) {
      // navigate('/home');
    }
  }, [])

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSend = e => {
    e.preventDefault();

    if (!name) {
      setNameError('Please enter your name.');
    } else {
      setNameError('');
    }

    if (!phone) {
      setPhoneError('Phone number is required.');
    } else if (!/^(\d{10}|\d{12})$/.test(phone)) {
      setPhoneError('Enter a valid 10-digit or 12-digit phone number.');
    } else {
      setPhoneError('');
    }

    if (!email) {
      setEmailError('Email is required.');
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError('Enter a valid email address.');
    } else {
      setEmailError('');
    }

    if (!message) {
      setMessageError('Please enter your message.');
    } else {
      setMessageError('');
    }

    const recaptchaValue = recaptchaRef.current.getValue();
    
    // console.log('recaptcha', recaptchaValue);

    if (!name || nameError || !phone || phoneError || !email || emailError || !message || messageError) {
      return Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please check the input fields.',
        showConfirmButton: true,
      });
      
    } else {

      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
      recaptchaRef.current.reset();

      generalApi.general().addUserMessage({ name, email, phone, message, recaptchaValue })
      .then(res => {
        
        if (res.data.success) {
          Swal.fire({
            icon: 'success',
            title: 'Thank you!',
            text: `Your message has sent. We aim to reply within 24 hours.`,
            showConfirmButton: false,
            timer: 3000,
          });
          
        } else {
          console.log(res.data.error);

          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: res.data.error,
            showConfirmButton: true,
          });
        }
        
      })
      .catch(err => {

        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Failed to send.',
          showConfirmButton: true,
        });
      });
    }

    
    
  };

  return (
    <Box m="0.5rem 1rem" display={"flex"} justifyContent={"center"} flexDirection={"column"}>
      <Box
        // mt="20px"
        display="flex"
        justifyContent={"space-around"}
        width={"100%"}
        minWidth={"400px"}
        // gridTemplateColumns="repeat(12, 1fr)"
        // gridAutoRows="160px"
        borderRadius="0.55rem"
        p="1rem"
        backgroundColor={theme.palette.background.alt}            
      >
        <Box
          display="flex"
          // flexGrow={0.3}
          width={'30%'}
          minWidth={'350px'}
          justifyContent={"center"}
        >
          <Box m="0.5rem 0.5rem" width={"100%"}>
            <Box
              // mt="20px"
              display="flex"
              justifyContent={"center"}
              // p="1rem"
              backgroundColor={theme.palette.background.alt}
            >
              <Box
                // gridColumn="span 12"
                // gridRow="span 6"
                width={'100%'}
                
                // p="1rem"
                borderRadius="0.55rem"
              >
                <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }} style={{paddingLeft: "1rem"}}>
                  Let's hear from you
                </Typography>
                <Box
                  p="1rem"
                >
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridAutoRows="80px"
                  >
                    <Box
                      width="100%"
                      gridColumn="span 12"
                      gridRow="span 1"
                      // backgroundColor={theme.palette.background.alt}
                      p="1rem"
                    >
                      <TextField
                        required
                        id="name"
                        label="Name"
                        style={{width:"100%"}}
                        defaultValue=""
                        value={name}
                        onChange={handleNameChange}
                        error={!!nameError}
                        helperText={nameError}
                      />
                    </Box>  
                    <Box
                      width="100%"
                      gridColumn="span 12"
                      gridRow="span 1"
                      // backgroundColor={theme.palette.background.alt}
                      p="1rem"
                    >
                      <TextField
                        required
                        id="email"
                        label="Email"
                        style={{width:"100%"}}
                        defaultValue=""
                        value={email}
                        onChange={handleEmailChange}
                        error={!!emailError}
                        helperText={emailError}
                      />
                    </Box>  
                    <Box
                      width="100%"
                      gridColumn="span 12"
                      gridRow="span 1"
                      // backgroundColor={theme.palette.background.alt}
                      p="1rem"
                    >
                      <TextField
                        required
                        id="phone"
                        label="Phone"
                        style={{width:"100%"}}
                        defaultValue=""
                        value={phone}
                        onChange={handlePhoneChange}
                        error={!!phoneError}
                        helperText={phoneError}
                      />
                    </Box>   
                    <Box
                      width="100%"
                      gridColumn="span 12"
                      gridRow="span 2"
                      // backgroundColor={theme.palette.background.alt}
                      p="1rem"
                    >
                      <TextField
                        multiline
                        rows={5}
                        maxRows={6}
                        required
                        id="message"
                        label="Message"
                        style={{width:"100%"}}
                        defaultValue=""
                        value={message}
                        onChange={handleMessageChange}
                        error={!!messageError}
                        helperText={messageError}
                      />
                    </Box> 
                  </Box>
                  <Box
                    width="100%"
                    gridColumn="span 3"
                    gridRow="span 1"
                    // backgroundColor={theme.palette.background.alt}
                    p="1rem"
                  >
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Lfn-t8oAAAAAAkjsNcWaXxWdjTFdRsCwjkIm8Xz"
                    />
                  </Box>
                  <Box
                    width="100%"
                    gridColumn="span 3"
                    gridRow="span 1"
                    // backgroundColor={theme.palette.background.alt}
                    p="1rem"
                  >
                    <Button id="submit" variant="contained" onClick={handleSend}>Send</Button>
                  </Box>
                </Box>        
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display={isNonMobile ?"flex": "none"}
          // flexGrow={1}
          width={'70%'}
          justifyContent={"center"}
          alignItems={"center"}
        >              
          <img src={logo} alt="logo" className={classes.logotypeImage} />
        </Box>
      </Box>
      <Box
        mt="20px"
        display="flex"
        justifyContent={"space-around"}
        width={"100%"}
        minWidth={"400px"}
        // gridTemplateColumns="repeat(12, 1fr)"
        // gridAutoRows="160px"
        borderRadius="0.55rem"
        p="1rem"
        backgroundColor={theme.palette.background.alt}            
      >
        <Bottom path="contacts" />
      </Box>
    </Box> 
  );
};

export default Profile;
