import React from "react";
import { Typography, Box, useTheme } from "@mui/material";

const Header = ({ title, subTitle }) => {
  const theme = useTheme();

  return (
    <Box sx={{borderBottom: 'solid 1px white'}} >
      <Typography
        variant="h5"
        color={theme.palette.secondary[100]}
        fontWeight="bold"
        sx={{ mx: "10px", my: "10px" }}
      >
        {title}
      </Typography>
      <Typography 
        variant="h5" 
        color={theme.palette.secondary[300]}        
        sx={{ mx: "10px", my: "10px" }}
      >
        {subTitle}
      </Typography>
    </Box>
  );
};

export default Header;
