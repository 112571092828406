import React from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PublicOutlined,
  PointOfSaleOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  AdminPanelSettingsOutlined,
  TrendingUpOutlined,
  PieChartOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import profileImage from "assets/profie.jpeg";
import { capitalizeRole } from "utilities/CommonUtility";

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  let navItems = [];
  if (user.role === "merchant") {
    navItems = [
      {
        text: "Dashboard",
        icon: <HomeOutlined />,
      },
      {
        text: "Transactions",
        icon: <ReceiptLongOutlined />,
      },
      {
        text: "Point of Sale",
        icon: <PointOfSaleOutlined />,
      },
      {
        text: "Profile",
        icon: <PublicOutlined />,
      },
    ];
    
  } else if (user.role === "admin" || user.role === "superadmin") {
    navItems = [
      {
        text: "Dashboard",
        icon: <HomeOutlined />,
      },
      {
        text: "Merchants",
        icon: <Groups2Outlined />,
      },
      {
        text: "Transactions",
        icon: <ReceiptLongOutlined />,
      },
      // {
      //   text: "Point of Sale",
      //   icon: <PointOfSaleOutlined />,
      // },
      // {
    //   text: "Refund Request",
    //   icon: <PublicOutlined />,
    // },
    // {
    //   text: "Geography",
    //   icon: <PublicOutlined />,
    // },
    // {
    //   text: "Sales",
    //   icon: null,
    // },
    // {
    //   text: "Overview",
    //   icon: <PointOfSaleOutlined />,
    // },
    // {
    //   text: "Daily",
    //   icon: <TodayOutlined />,
    // },
    // {
    //   text: "Monthly",
    //   icon: <CalendarMonthOutlined />,
    // },
    // {
    //   text: "Breakdown",
    //   icon: <PieChartOutlined />,
    // },
    // {
    //   text: "Management",
    //   icon: null,
    // },
    // {
    //   text: "Admin",
    //   icon: <AdminPanelSettingsOutlined />,
    // },
    // {
    //   text: "Performance",
    //   icon: <TrendingUpOutlined />,
    // },
    ];
  }

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  return (
    <Box component="nav">
      <Box width="100%">        
        <List>
          {navItems.map(({ text, icon }) => {            
            const lcText = text.toLowerCase().replaceAll(' ', '_');
            // console.log('lcText', lcText);
            return (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(`/${lcText}`);
                    setActive(lcText);
                  }}
                  sx={{
                    backgroundColor:
                      active === lcText
                        ? theme.palette.secondary[300]
                        : "transparent",
                    color:
                      active === lcText
                        ? theme.palette.primary[600]
                        : theme.palette.secondary[100],
                  }}
                >
                  <ListItemIcon
                    sx={{
                      ml: "2rem",
                      color:
                        active === lcText
                          ? theme.palette.primary[600]
                          : theme.palette.secondary[200],
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                  {active === lcText && (
                    <ChevronRightOutlined sx={{ ml: "auto" }} />
                  )}
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};

export default Sidebar;
