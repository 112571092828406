import React, { useMemo, useState, useEffect, useRef, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate, useLocation  } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import PayPalButton from "components/PayPalButton";
import { ResponsiveLine } from "@nivo/line";
import DatePicker from "react-datepicker";
import { styled } from '@mui/material/styles';
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import {
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetUserQuery, generalApi } from "state/api";
import Swal from 'sweetalert2';
import {
  Bottom
} from "scenes";

import ReCAPTCHA from "react-google-recaptcha";

// styles
import useStyles from "../../assets/dashboard_styles";
// logo
import logo from "../../assets/main-image.png";

const PasswordRecovery = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const { getAuthUser} = useContext(AuthContext);
  let authUser = getAuthUser();
  const navigate = useNavigate();
  const location = useLocation();

  // local
  var [errorMessage, setErrorMessage] = useState(null);

  const [encryptCode, setEncryptCode] = useState("");

  const [verifyCode, setVerifyCode] = useState("");
  var [emailValue, setEmailValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");

  const [step, setStep] = useState(0);
  const [contact, setContact] = useState({
      address: ""
  });
    
  const onNext = () => {
    setStep(step + 1);
  }

  const onBack = () => {
      setStep(step - 1);
  }

  const goToHome = () => {
      navigate('/home');
  }

  useEffect(() => {
      // if (location.state?.contact) {
      //     setContact(location.state.contact);
      // }
      // else {
      //     navigate('/home');
      // }
  }, [location])

  const handleEmailChange = (event) => {
    setEmailValue(event.target.value);
  };

  const handleVerifyCodeChange = (event) => {
    setVerifyCode(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPasswordValue(event.target.value);
  };

  const handleEmailSend = e => {
    e.preventDefault();

    if (!emailValue) {
      return Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please check the input field.',
        showConfirmButton: true,
      });
    }

    generalApi.general().sendEmailVerifyCode({ email: emailValue })
    .then(res => {
      
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Sent!',
          text: 'Password reset request has been sent to your mail, please keep this window open to enter your activation code', // `An email with a verification code was just sent to ${emailValue}.`,
          showConfirmButton: true,
          // timer: 2000,
        });
        setEncryptCode(res.data.encryptCode);
        onNext();
      } else {
        console.log(res.data.error);

        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: res.data.error,
          showConfirmButton: true,
        });
      }
      
    })
    .catch(err => {

      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to send email with a verification code.',
        showConfirmButton: true,
      });
    });
    
  };

  const handleVerifyCodeSend = e => {
    e.preventDefault();

    if (!verifyCode) {
      return Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please check the input field.',
        showConfirmButton: true,
      });
    }

    generalApi.general().checkVerifyCode({ verifyCode, encryptCode })
    .then(res => {
      // console.log('verify result', res.data);
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Verified!',
          text: `Your email, ${emailValue}' has been verified.`,
          showConfirmButton: false,
          timer: 2000,
        });
        onNext();
      } else {
        console.log(res.data.error);

        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: res.data.error,
          showConfirmButton: true,
        });
      }
      
    })
    .catch(err => {
      // console.log('verify error', err)
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to verify.',
        showConfirmButton: true,
      });
    });
    
  };

  const handlePasswordUpdate = e => {
    e.preventDefault();

    if (!passwordValue) {
      return Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please check the input field.',
        showConfirmButton: true,
      });
    }

    generalApi.general().resetPassword({ email: emailValue, password: passwordValue })
    .then(res => {
      
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Reset!',
          text: `${emailValue}'s password has been reset.`,
          showConfirmButton: false,
          timer: 2000,
        });
        goToHome();
      } else {
        console.log(res.data.error);

        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: res.data.error,
          showConfirmButton: true,
        });
      }
      
    })
    .catch(err => {

      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to reset.',
        showConfirmButton: true,
      });
    });
    
  };

  const render = () => {
      if (step === 0)
        return <Box
          gridColumn="span 12"
          gridRow="span 6"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          // my="1rem"
          borderRadius="0.55rem"
        >
          <Typography 
            variant="h6" 
            sx={{ color: theme.palette.secondary[100] }}
          >
            Send a verification code to your email
          </Typography>
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="80px"
          >                      
            <Box
              width="100%"
              gridColumn="span 12"
              gridRow="span 1"
              // backgroundColor={theme.palette.background.alt}
              p="1rem"
            >
              <TextField                          
                id="email"
                label="Email"
                style={{width:"100%"}}
                value={emailValue}
                onChange={handleEmailChange}
              />
            </Box> 
            <Box
              width="100%"
              gridColumn="span 12"
              gridRow="span 1" 
              p="1rem"
            >
              <Button id="submit" variant="contained" onClick={handleEmailSend}>Send</Button>      
            </Box>                              
          </Box>
        </Box>
      else if (step === 1)
        return <Box
          gridColumn="span 12"
          gridRow="span 6"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          // my="1rem"
          borderRadius="0.55rem"
        >
          <Typography 
            variant="h6" 
            sx={{ color: theme.palette.secondary[100] }}
          >
            Please enter verify code
          </Typography>
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="80px"
          >                      
            <Box
              width="100%"
              gridColumn="span 12"
              gridRow="span 1"
              // backgroundColor={theme.palette.background.alt}
              p="1rem"
            >
              <TextField                          
                id="verifycode"
                label="Verify Code"
                style={{width:"100%"}}
                value={verifyCode}
                onChange={handleVerifyCodeChange}
              />
            </Box> 
            <Box
              width="100%"
              gridColumn="span 12"
              gridRow="span 1" 
              p="1rem"
            >
              <Button id="submit" variant="contained" onClick={handleVerifyCodeSend}>Next</Button>      
            </Box>                              
          </Box>
        </Box> 
      else if (step === 2)
        return <Box
          gridColumn="span 12"
          gridRow="span 6"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          // my="1rem"
          borderRadius="0.55rem"
        >
          <Typography 
            variant="h6" 
            sx={{ color: theme.palette.secondary[100] }}
          >
            Change Password
          </Typography>
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="80px"
          >                        
            <Box
              width="100%"
              gridColumn="span 12"
              gridRow="span 1"
              // backgroundColor={theme.palette.background.alt}
              p="1rem"
            >
              <TextField
                id="new"
                label="New Password"
                style={{width:"100%"}}
                value={passwordValue}
                onChange={handlePasswordChange}
              />
            </Box>
            <Box
              width="100%"
              gridColumn="span 12"
              gridRow="span 1" 
              p="1rem"
            >
              <Button id="submit" variant="contained" onClick={handlePasswordUpdate}>Change</Button>      
            </Box>     
          </Box>
        </Box>
  }

  return (
    
    <Box m="1rem" display={"flex"} justifyContent={"center"} flexDirection={"column"}>
    <Box
      // mt="20px"
      display="flex"
      justifyContent={"space-around"}
      width={"100%"}
      minWidth={"400px"}
      // gridTemplateColumns="repeat(12, 1fr)"
      // gridAutoRows="160px"
      borderRadius="0.55rem"
      p="1rem"
      backgroundColor={theme.palette.background.alt}            
    >
      <Box
        display="flex"
        // flexGrow={0.3}
        width={'30%'}
        minWidth={'350px'}
        justifyContent={"center"}
      >
        <Box m="0.5rem 0.5rem" width={"100%"}>
          <Box
            // mt="20px"
            display="flex"
            justifyContent={"center"}
            // p="1rem"
            backgroundColor={theme.palette.background.alt}
          >
            <Box
              // gridColumn="span 12"
              // gridRow="span 6"
              width={'100%'}
              
              // p="1rem"
              borderRadius="0.55rem"
            >
              <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }} style={{paddingLeft: "1rem"}}>
                Password Recovery
              </Typography>
              
                <Box
                  // height="80vh"
                  sx={{
                    "& .MuiDataGrid-root": {
                      border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: theme.palette.background.alt,
                      color: theme.palette.secondary[100],
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      backgroundColor: theme.palette.primary.light,
                    },
                    "& .MuiDataGrid-footerContainer": {
                      backgroundColor: theme.palette.background.alt,
                      color: theme.palette.secondary[100],
                      borderTop: "none",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                      color: `${theme.palette.secondary[200]} !important`,
                    },
                    "& .MuiFormLabel-root": {
                      color: `${theme.palette.primary[200]} !important`,
                    },
                    "& .MuiInputBase-root": {
                      color: `${theme.palette.secondary[300]} !important`,
                    },
                  }}
                >
                  {render()}
                </Box>  

            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display={isNonMobile ?"flex": "none"}
        // flexGrow={1}
        width={'70%'}
        justifyContent={"center"}
        alignItems={"center"}
      >              
        <img src={logo} alt="logo" className={classes.logotypeImage} />
      </Box>
    </Box>
    <Box
      mt="20px"
      display="flex"
      justifyContent={"space-around"}
      width={"100%"}
      minWidth={"400px"}
      // gridTemplateColumns="repeat(12, 1fr)"
      // gridAutoRows="160px"
      borderRadius="0.55rem"
      p="1rem"
      backgroundColor={theme.palette.background.alt}            
    >
      <Bottom path="dashboard" />
    </Box>
  </Box>        
  );
};

export default PasswordRecovery;