import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Box, useTheme, useMediaQuery, Button } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Header from "components/Header";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { generalApi } from "state/api"
import { MarginOutlined } from '@mui/icons-material';
import { CKEditor } from 'ckeditor4-react';

const Add = ({ setIsAdding }) => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  
  const [subjectError, setSubjectError] = useState('');  
  const [textError, setTextError] = useState('');  

  const [editorValue, setEditorValue] = useState('');
  const [editor, setEditor] = useState(null);
  
  const [loading, setLoading] = useState(false);

  const onEditorChange = evt => {
    setEditorValue(evt.editor.getData());
  }

  const onInstanceReady = evt => {
    setEditor(evt.editor);    
  }

  const handleAdd = e => {
    e.preventDefault();

    if (!subject) {
      setSubjectError('Please enter the subject of mail.');
    } else {
      setSubjectError('');
    }
    
    // console.log('editorValue', editorValue);
    // return;
    
    // if (!text) {
    //   setTextError('Please enter the content of mail.');
    // } else {
    //   setTextError('');
    // }

    if (!subject || subjectError) {
      return Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please check the input fields.',
        showConfirmButton: true,
      });
    }

    setLoading(true);

    generalApi.general().sendMassMails({ subject, text: editorValue })
    .then(res => {
      
	  setLoading(false);

      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Sent!',
          text: `Mass mail has been sent.`,
          showConfirmButton: false,
          timer: 1500,
        });

        setSubject('');
        setText('');
        // refetch();
        setIsAdding(false);
      } else {
        console.log(res.data.error);

        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: `Failed to send(${res.data.error}).`,
          showConfirmButton: true,
        });
      }
      
    })
    .catch(err => {

	  setLoading(false);

      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to send mass mail.',
        showConfirmButton: true,
      });
    });
    
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  return (
    <Box
      mt="20px"
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gridAutoRows="60px"
      gap="20px"
      sx={{
        "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
      }}
    >
      <Box
        gridColumn="span 12"
        gridRow="span 7"
        backgroundColor={theme.palette.background.alt}
        p="1rem"
        borderRadius="0.55rem"
      >
        <Header title="" subTitle="Create Mass Mail" />
		{
		loading 
        ?
        "Sending Email to all users. Please don't take actions until you get the result..."
        :
		<>
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="80px"
        >
          <Box
            width="100%"
            gridColumn="span 12"
            gridRow="span 1"
            // backgroundColor={theme.palette.background.alt}
            p="1rem"
          >
            <TextField
              required
              id="subject"
              label="Subject"
              style={{width:"100%"}}
              defaultValue=""
              value={subject}
              onChange={handleSubjectChange}
              error={!!subjectError}
              helperText={subjectError}
            />
          </Box>  
          <Box
            width="100%"
            gridColumn="span 12"
            gridRow="span 4"
            // backgroundColor={theme.palette.background.alt}
            p="1rem"
          >
            <CKEditor
              data={editorValue}
              onChange={onEditorChange}
              onInstanceReady={onInstanceReady}
              config= {{
                toolbar: [
                  ["Undo", "Redo"],
                  ["NumberedList", "BulletedList", 'Outdent', 'Indent'],
                  { name: 'styles', items: ['Font', 'FontSize'] },
                  // ["Font", "FontSize"],
                  ["Bold", "Italic", 'Underline', 'Strike'],
                  ['TextColor', 'BGColor'], 
                  ["Link"],
                  ['Image'],
                  ["EmojiPanel"],
                ],
                contentsCss: 'http://localhost:3000/ckeditor/contents.css',
                image_previewText: "",
                extraPlugins: "font,emoji,colorbutton", // Include the names of your plugins here
              }}
              scriptUrl="http://localhost:3000/ckeditor/ckeditor.js"
            />
            {/* <TextField
              required
              multiline
              rows={5}
              maxRows={6}
              id="text"
              label="Content"
              style={{width:"100%"}}
              defaultValue=""
              value={text}
              onChange={handleTextChange}
              error={!!textError}
              helperText={textError}
            /> */}
          </Box>          
        </Box>
        <Box
          width="100%"
          gridColumn="span 12"
          gridRow="span 1"
          // backgroundColor={theme.palette.background.alt}
          p="1rem"
          style={{display: 'flex', justifyContent: 'end'}}
        >          
          <Button id="cancel" variant="contained" onClick={() => {setIsAdding(false);setSubject('');setText('');}} style={{marginRight: '10px'}}>Cancel</Button>
          <Button id="submit" variant="contained" onClick={handleAdd}>Send</Button>
        </Box> 
		</>	
		}
      </Box>
    </Box>   
  );
};

export default Add;
