import React, { useMemo, useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate  } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import { ResponsiveLine } from "@nivo/line";
import DatePicker from "react-datepicker";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import {
  DownloadOutlined,
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
} from "@mui/icons-material";
import { Groups2Outlined, PointOfSaleOutlined } from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import BreakdownChart from "components/BreakdownChart";
import OverviewChart from "components/OverviewChart";
import { useGetDashboardQuery, useGetSettingQuery, generalApi } from "state/api";
import StatBox from "components/StatBox";
import Swal from 'sweetalert2';

const Dashboard = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const { getAuthUser} = useContext(AuthContext);
  const authUser = getAuthUser();
  const navigate = useNavigate();

  const {data, isLoading, refetch} = useGetSettingQuery();
  
  const [price, setPrice] = useState('');
  const [priceError, setPriceError] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');

  useEffect(() => {
    if (authUser === null) {
      navigate('/home');
    }
  }, [authUser])

  useEffect(() => {
    // console.log('data', data);
    if (data !== null) {
      setPrice(data?.price);
      setPaymentStatus(data?.status);
    }
  }, [data])

  const handlePriceUpdate = e => {
    e.preventDefault();

    if (!price) {
      setPriceError('Price is required.');
    } else {
      const parsedPrice = parseFloat(price);
  
      if (isNaN(parsedPrice) || parsedPrice <= 0) {
        setPriceError('Invalid Price.');
      } else {
        setPriceError('');
      }
    }

    if (!price || priceError) {
      return Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please check the input field of Price.',
        showConfirmButton: true,
      });
    }

    generalApi.general().updatePrice({ id: data._id, price })
    .then(res => {
      
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Updated!',
          text: `Price has been updated.`,
          showConfirmButton: false,
          timer: 3000,
        });
        refetch();
      } else {
        console.log(res.data.error);

        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Failed to update.',
          showConfirmButton: true,
        });
      }
      
    })
    .catch(err => {

      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to update.',
        showConfirmButton: true,
      });
    });
    
  };

  const handlePaymentUpdate = e => {
    e.preventDefault();

    if (!paymentStatus) {
      return Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please check the input field of Payment Status.',
        showConfirmButton: true,
      });
    }

    generalApi.general().updatePaymentStatus({ id: data._id, paymentStatus })
    .then(res => {
      
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Updated!',
          text: `Payment status has been updated.`,
          showConfirmButton: false,
          timer: 3000,
        });
        refetch();
      } else {
        console.log(res.data.error);

        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Failed to update.',
          showConfirmButton: true,
        });
      }
      
    })
    .catch(err => {

      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to update.',
        showConfirmButton: true,
      });
    });
    
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handlePaymentChange = (event) => {
    setPaymentStatus(event.target.value);
  };

  const dateToString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 as month is zero-based
    const day = String(date.getDate()).padStart(2, '0');

    // Format the date string
    const formattedDate = `${year}-${month}-${day}`;
    // console.log(formattedDate);
    
    return formattedDate;
  }

  // Current date
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');  
  const curDate = `${year}-${month}-${day}`;

  currentDate.setMonth(currentDate.getMonth() - 1);
  const pyear = currentDate.getFullYear();
  const pmonth = String(currentDate.getMonth() + 1).padStart(2, '0');
  const pday = String(currentDate.getDate()).padStart(2, '0');

  const preDate = `${pyear}-${pmonth}-${pday}`;
  // console.log(preDate, curDate);
 
  const [startDate, setStartDate] = useState(new Date(preDate));
  const [endDate, setEndDate] = useState(new Date(curDate));
  // console.log('endDate', endDate);

  const data0 = useGetDashboardQuery({id: authUser, startDate: dateToString(startDate), endDate: dateToString(endDate)});

  return (
    <Box m="1rem">
      <FlexBetween>
        <Header title="Overview" subTitle="" />
        <Box>
          <Box>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </Box>
          <Box>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </Box>
        </Box>
      </FlexBetween>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* ROW 1 */}
        <StatBox
          title="TOTAL REGISTERED USERS"
          value={data0?.data && data0?.data.userCount}
          increase=""
          description=""
          icon={
            <Groups2Outlined
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="TOTAL PURCHASED USERS"
          value={data0?.data && data0?.data.purchaseCount}
          increase=""
          description=""
          icon={
            <PointOfSaleOutlined
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />        
        <StatBox
          title="TOTAL VOLUME PROCESSED (USD)"
          value={data0?.data && data0?.data.usdAmount}
          increase=""
          description=""
          icon={
            <AttachMoneyIcon
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />    
      </Box>
      <Box
        mt="2rem"
      >    
        {/* ROW 2 */}
        <Header title="Setting" subTitle="" />
        <Box
          gridColumn="span 12"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          my="1rem"
          p="1rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            PRICE SETTING
          </Typography>
          <Box
            height={"95%"}
            position="relative"
            p="1rem"
          >
            <TextField
              id="price"
              label="Price(USD)"
              style={{width:"100%"}}
              value={price}
              onChange={handlePriceChange}
            />
            <Box
              pt="1rem"
            >
              <Button id="price" variant="contained" onClick={handlePriceUpdate}>Change</Button>
            </Box>
          </Box>
        </Box>
        <Box
          gridColumn="span 12"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          my="1rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            PAYMENT SETTING
          </Typography>
          <Box
            height={"95%"}
            position="relative"
            p="1rem"
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Payment</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // defaultValue={'USD'}
                value={paymentStatus}
                label="Payment"
                onChange={handlePaymentChange}
              >
                <MenuItem value={'activated'}>Activated</MenuItem>
                <MenuItem value={'deactivated'}>Deactivated</MenuItem>
              </Select>
            </FormControl>
            <Box
              pt="1rem"
            >
              <Button id="payment" variant="contained" onClick={handlePaymentUpdate}>Change</Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;