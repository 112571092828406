import React, { useMemo, useState, useEffect, useRef, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate  } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import PayPalButton from "components/PayPalButton";
import { ResponsiveLine } from "@nivo/line";
import DatePicker from "react-datepicker";
import { styled } from '@mui/material/styles';
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import {
  Box,
  Link,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetUserQuery, generalApi } from "state/api";
import Swal from 'sweetalert2';
import {
  Bottom
} from "scenes";

import ReCAPTCHA from "react-google-recaptcha";

// styles
import useStyles from "../../assets/dashboard_styles";
// logo
import logo from "../../assets/main-image.png";

const Home = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const { getAuthUser} = useContext(AuthContext);
  let authUser = getAuthUser();
  const navigate = useNavigate();

  const { login, register } = useContext(AuthContext)
  let { data, isLoading, refetch } = useGetUserQuery(authUser?.id);
  // console.log(authUser);

  const recaptchaRef = React.createRef();

  // local
  var [isLoadingLogin, setIsLoadingLogin] = useState(false);
  var [errorMessage, setErrorMessage] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [emailValue, setEmailValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");

  const [isChangePassword, setChangePassword] = useState(false);
  const totalAmount = "100";
  const [oldP, setOldP] = useState("");
  const [newP, setNewP] = useState("");
  const [oldPError, setOldPError] = useState('');  
  const [newPError, setNewPError] = useState('');
  const [isPayment, setPayment] = useState(false);
  const textFieldRef = useRef();

  const handleCopy = () => {
      const textField = textFieldRef.current;
      textField.select();
      document.execCommand('copy');
      Swal.fire({
        icon: 'success',
        title: 'Copied!',
        text: `Licese key has been copied to Clipboard.`,
        showConfirmButton: false,
        timer: 3000,
      });
  };
  const handleChangeTab = (id) => {
    // alert(id);
    setActiveTabId(id)
    // setErrorMessage(null)
  }

  const handleForgotPassword = () => {
    navigate('/passwordrecovery');
  }

  const handlePurchase = e => {
    e.preventDefault();
    // alert('licenseKey: ' + authUser?.licenseKey);
    setPayment(true);
    
  }

  const handleLogin = async () => {
    const isLoggedin = await login(
      emailValue,
      passwordValue,
      // props.history,
      setIsLoadingLogin,
      setErrorMessage
    );
    // console.log('ggg', isLoggedin);
    if (isLoggedin.success) {
      navigate('/');
      // authUser = getAuthUser();
      // data = authUser;
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Failed to login!',
        text: isLoggedin.error,
        showConfirmButton: true,
      });
    }
  }

  const handleRegister = async () => {

    const recaptchaValue = recaptchaRef.current.getValue();    
    recaptchaRef.current.reset();

    const isRegistered = await register(
      emailValue,
      nameValue,
      passwordValue,
      recaptchaValue,
      setIsLoadingLogin,
      setErrorMessage,
    )
    console.log(isRegistered);
    // alert(isRegistered);
    if (isRegistered.success) {
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: `You have registered.`,
        showConfirmButton: false,
        timer: 3000,
      });
      setActiveTabId(0);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: isRegistered.error,
        showConfirmButton: true,
      });
    }
  }

  useEffect(() => {
    if (authUser === null) {
      // navigate('/login');
      // alert('Not logged in')
    }
  }, [authUser])

  const handleOldPChange = (event) => {
    setOldP(event.target.value);
  };

  const handleNewPChange = (event) => {
    setNewP(event.target.value);
  };

  const handleUpdate = e => {
    e.preventDefault();

    if (!oldP) {
      setOldPError('Please enter old password.');
    } else {
      setOldPError('');
    }

    if (!newP) {
      setNewPError('Please enter new Password.');
    } else {
      setNewPError('');
    }

    if (!oldP || oldPError || !newP || newPError) {
      return Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please check the input fields.',
        showConfirmButton: true,
      });
    }

    generalApi.general().updatePassword({ id: authUser.id, oldP, newP })
    .then(res => {
      
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Changed!',
          text: `${authUser.name}'s password has been changed.`,
          showConfirmButton: false,
          timer: 3000,
        });
        setNewP("");
        setOldP("");
        setChangePassword(false);
      } else {
        console.log(res.data.error);

        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: res.data.error,
          showConfirmButton: true,
        });
      }
      
    })
    .catch(err => {

      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to change.',
        showConfirmButton: true,
      });
    });
    
  };

  const resultMessage = (message, success) => {
    setPayment(false);
    Swal.fire({
      icon: success?'success':'error',
      title: 'Process result',
      text: message,
      showConfirmButton: true,
      confirmButtonText: 'Confirm',
    }).then(result => {
      if (result.value) {
        generalApi.general().updatePurchase({ id: authUser.id, status: success })
        .then(res => {
          
          if (res.data.success && success) {
            Swal.fire({
              icon: 'success',
              title: 'Purchased!',
              text: `Licese key has been generated.`,
              showConfirmButton: false,
              timer: 3000,
            });
            refetch();
          } else if (res.data.success && !success) {
            Swal.fire({
              icon: 'warning',
              title: 'Purchase failed!',
              text: `Licese key has not been generated.`,
              showConfirmButton: false,
              timer: 3000,
            });
            refetch();
          } else {
            console.log(res.data.error);

            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: res.data.error,
              showConfirmButton: true,
            });
            refetch();
          }
          
        })
        .catch(err => {

          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Failed to update.',
            showConfirmButton: true,
          });
          refetch();
        });
      }
    });    
      
  }

  return (
    
    <Box m="1rem" display={"flex"} justifyContent={"center"} flexDirection={"column"}>
    <Box
      // mt="20px"
      display="flex"
      justifyContent={"space-around"}
      width={"100%"}
      minWidth={"400px"}
      // gridTemplateColumns="repeat(12, 1fr)"
      // gridAutoRows="160px"
      borderRadius="0.55rem"
      p="1rem"
      backgroundColor={theme.palette.background.alt}            
    >
      <Box
        display="flex"
        // flexGrow={0.3}
        width={'30%'}
        minWidth={'350px'}
        justifyContent={"center"}
      >
        <Box m="0.5rem 0.5rem" width={"100%"}>
          <Box
            // mt="20px"
            display="flex"
            justifyContent={"center"}
            // p="1rem"
            backgroundColor={theme.palette.background.alt}
          >
            <Box
              // gridColumn="span 12"
              // gridRow="span 6"
              width={'100%'}
              
              // p="1rem"
              borderRadius="0.55rem"
            >
              <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }} style={{paddingLeft: "1rem"}}>
                { authUser ?
                  `Welcome ${authUser.name}` :
                  `Welcome Guest`
                }
              </Typography>
              { 
                authUser ?
                <Box
                  // height="80vh"
                  sx={{
                    "& .MuiDataGrid-root": {
                      border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: theme.palette.background.alt,
                      color: theme.palette.secondary[100],
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      backgroundColor: theme.palette.primary.light,
                    },
                    "& .MuiDataGrid-footerContainer": {
                      backgroundColor: theme.palette.background.alt,
                      color: theme.palette.secondary[100],
                      borderTop: "none",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                      color: `${theme.palette.secondary[200]} !important`,
                    },
                    "& .MuiFormLabel-root": {
                      color: `${theme.palette.primary[200]} !important`,
                    },
                    "& .MuiInputBase-root": {
                      color: `${theme.palette.secondary[300]} !important`,
                    },
                  }}
                >
                  <Box
                    gridColumn="span 12"
                    // gridRow="span 6"
                    backgroundColor={theme.palette.background.alt}
                    p="1rem"
                    borderRadius="0.55rem"
                    color="white"
                  >
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gridAutoRows="80px"
                    >
                      <Box
                        width="100%"
                        gridColumn="span 12"
                        gridRow="span 1"
                        // backgroundColor={theme.palette.background.alt}
                        p="1rem"
                      >
                        <TextField
                          // disabled
                          InputProps={{
                            readOnly: true,
                          }}
                          id="name"
                          label="User Name"
                          style={{width:"100%"}}
                          defaultValue={authUser.name}
                        />
                      </Box>
                      <Box
                        width="100%"
                        gridColumn="span 12"
                        gridRow="span 1"
                        // backgroundColor={theme.palette.background.alt}
                        p="1rem"
                      >
                        <TextField
                          // disabled
                          InputProps={{
                            readOnly: true,
                          }}
                          id="email"
                          label="Email"
                          style={{width:"100%"}}
                          defaultValue={authUser.email}
                        />
                      </Box>
                      { data?.role === "player" &&
                        <>
                        { (data?.licenseKey && data?.licenseKey !== "") ?
                          <Box
                            width="100%"
                            gridColumn="span 12"
                            gridRow="span 2"
                            // backgroundColor={theme.palette.background.alt}
                            p="1rem"
                          >
                            <TextField
                              // disabled
                              InputProps={{
                                readOnly: true,
                                style: {fontSize: 11}
                              }}
                              inputRef={textFieldRef}
                              id="licenseKey"
                              label="License Key"
                              style={{width:"100%"}}
                              defaultValue={data?.licenseKey}
                            />
                            <Button onClick={handleCopy} variant="contained" color="primary" style={{ marginTop: '10px' }}>
                              Copy to Clipboard
                            </Button>
                          </Box> :
                          <>
                            <Box
                              width="100%"
                              gridColumn="span 12"
                              gridRow="span 1" 
                              p="1rem"
                            >
                              <Typography>License Key Price: {authUser.price} USD</Typography> 
                              <Typography>Payment Processor: {authUser.paymentStatus === 'activated'?authUser.payment:'No processor'}</Typography>    
                            </Box>
                            {authUser.paymentStatus === 'activated' &&
                              <Box
                                width="100%"
                                gridColumn="span 12"
                                gridRow="span 1" 
                                p="1rem"
                              >
                                <Button id="purchase" variant="contained" onClick={handlePurchase}>Purchase</Button>                                
                              </Box>
                            }
                          </>
                        }
                        </>
                      }                                     
                    </Box>          
                  </Box>
                  <Box
                    gridColumn="span 12"
                    gridRow="span 6"
                    backgroundColor={theme.palette.background.alt}
                    p="1rem"
                    // my="1rem"
                    borderRadius="0.55rem"
                  >
                    <Typography 
                      variant="h6" 
                      sx={{ color: theme.palette.secondary[100] }}
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        setChangePassword(!isChangePassword);
                      }}
                    >
                      Change Password
                    </Typography>
                    { isChangePassword &&
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="80px"
                      >
                        <Box
                          width="100%"
                          gridColumn="span 12"
                          gridRow="span 1"
                          // backgroundColor={theme.palette.background.alt}
                          p="1rem"
                        >
                          <TextField
                            id="old"
                            label="Old Password"
                            style={{width:"100%"}}
                            value={oldP}
                            onChange={handleOldPChange}
                            error={!!oldPError}
                            helperText={oldPError}
                          />
                        </Box>
                        <Box
                          width="100%"
                          gridColumn="span 12"
                          gridRow="span 1"
                          // backgroundColor={theme.palette.background.alt}
                          p="1rem"
                        >
                          <TextField
                            id="new"
                            label="New Password"
                            style={{width:"100%"}}
                            value={newP}
                            onChange={handleNewPChange}
                            error={!!newPError}
                            helperText={newPError}
                          />
                        </Box>
                        <Box
                          width="100%"
                          gridColumn="span 12"
                          gridRow="span 1" 
                          p="1rem"
                        >
                          <Button id="submit" variant="contained" onClick={handleUpdate}>Change</Button>      
                        </Box>     
                      </Box> 
                    }                               
                  </Box>
                </Box> :
                <div className={classes.formContainer}>
                  <Box className={classes.form}
                    sx={{
                      "& .MuiFormLabel-root": {
                        color: `${theme.palette.primary[200]} !important`,
                      },
                      "& .MuiInputBase-root": {
                        color: `${theme.palette.secondary[300]} !important`,
                      },
                    }}
                  >
                    <Tabs
                      value={activeTabId}
                      onChange={(e, id) => handleChangeTab(id)}
                      indicatorColor="primary"
                      textColor="white"
                      centered
                    >
                      <Tab label="Login" classes={{ root: classes.tab }} />
                      <Tab label="New User" classes={{ root: classes.tab }} />
                    </Tabs>

                    {activeTabId === 0 && (
                      <React.Fragment>

                          {errorMessage && (
                            <Fade in={true}>
                              <Typography color="secondary" className={classes.errorMessage}>
                                {errorMessage.toString()}
                              </Typography>
                            </Fade>
                          )}

                          <TextField
                            id="email"
                            InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                              },
                            }}
                            value={emailValue}
                            onChange={e => setEmailValue(e.target.value)}
                            margin="normal"
                            label="Email"
                            placeholder="Email"
                            type="email"
                            fullWidth
                          />
                          <TextField
                            id="password"
                            InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                              },
                            }}
                            value={passwordValue}
                            onChange={e => setPasswordValue(e.target.value)}
                            margin="normal"
                            label="Password"
                            placeholder="Password"
                            type="password"
                            fullWidth
                          />
                          <Link
                            component="button"
                            underline="none"
                            color="white"
                            onClick={handleForgotPassword}
                          >
                            Forgot password?
                          </Link>
                          <div className={classes.formButtons}>
                            {isLoadingLogin ? (
                              <CircularProgress size={26} className={classes.loginLoader} />
                            ) : (

                              <Button
                                disabled={
                                  emailValue.length === 0 || passwordValue.length === 0
                                }
                                onClick={handleLogin}
                                variant="contained"
                                color="primary"
                                size="large"
                              >
                                Login
                              </Button>

                            )}
                            
                          </div>

                      </React.Fragment>
                    )}
                    {activeTabId === 1 && (
                      <React.Fragment>
                        
                        {errorMessage && (
                          <Fade in={true}>
                            <Typography color="secondary" className={classes.errorMessage}>
                              {errorMessage}
                            </Typography>
                          </Fade>
                        )}
                        <TextField
                          id="name"
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={nameValue}
                          onChange={e => setNameValue(e.target.value)}
                          margin="normal"
                          label="Name"
                          placeholder="Name"
                          type="text"
                          fullWidth
                        />
                        <TextField
                          id="email"
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={emailValue}
                          onChange={e => setEmailValue(e.target.value)}
                          margin="normal"
                          label="Email"
                          placeholder="Email"
                          type="email"
                          fullWidth
                        />
                        <TextField
                          id="password"
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={passwordValue}
                          onChange={e => setPasswordValue(e.target.value)}
                          margin="normal"
                          label="Password"
                          placeholder="Password"
                          type="password"
                          fullWidth
                        />
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey="6Lfn-t8oAAAAAAkjsNcWaXxWdjTFdRsCwjkIm8Xz"
                        />
                        <div className={classes.creatingButtonContainer}>
                          {isLoadingLogin ? (
                            <CircularProgress size={26} />
                          ) : (
                            <>     
                              <Button
                                onClick={handleRegister}
                                disabled={
                                  emailValue.length === 0 ||
                                  passwordValue.length === 0 ||
                                  nameValue.length === 0
                                }
                                size="large"
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={classes.createAccountButton}
                              >
                                Create your account
                              </Button>
                            </>                            
                          )}
                        </div>
                        
                      </React.Fragment>
                    )}

                  </Box>
                </div>
              }          
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display={isNonMobile ?"flex": "none"}
        // flexGrow={1}
        width={'70%'}
        justifyContent={"center"}
        alignItems={"center"}
      >              
      {
        isPayment ?
        <PayPalButton total={authUser.price} resultMessage={resultMessage} /> :
        <img src={logo} alt="logo" className={classes.logotypeImage} />
      }      
      </Box>
    </Box>
    <Box
      mt="20px"
      display="flex"
      justifyContent={"space-around"}
      width={"100%"}
      minWidth={"400px"}
      // gridTemplateColumns="repeat(12, 1fr)"
      // gridAutoRows="160px"
      borderRadius="0.55rem"
      p="1rem"
      backgroundColor={theme.palette.background.alt}            
    >
      <Bottom path="dashboard" />
    </Box>
  </Box>        
  );
};

export default Home;