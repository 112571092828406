import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate  } from "react-router-dom";
import { Box, useTheme, Button, useMediaQuery } from "@mui/material";
import { useGetMassMailsQuery, generalApi } from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
// import { merchantTableColumns } from "utilities/CommonUtility";
import DataGridCustomToolbarForMerchants from "components/DataGridCustomToolbarForMerchants";
import Add from './Add';
import Swal from 'sweetalert2';

const MassMails = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const [isAdding, setIsAdding] = useState(false);

  const { getAuthUser} = useContext(AuthContext);
  const authUser = getAuthUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (authUser === null || authUser.role === "player") {
      navigate('/home');
    }
  }, [authUser])

  const { data, isLoading, refetch } = useGetMassMailsQuery();
  
  const merchantTableColumns = [
    {
      field: "subject",
      headerName: "Subject",
      flex: 1,
    },
    {
      field: "text",
      headerName: "Content",
      flex: 3,
    },
    {
      field: "createdAt",
      headerName: "Sent Time",
      flex: 1
    },
    {
      field: "action",
      headerName: "Action",    
      sortable: false,
      filterable: false,
      flex: 0.5,
      // hide: true,
      renderCell: (params) => (
        <Button id="delete" variant="contained" onClick={() => handleDelete(params.row)}>Delete</Button>
      )
    },
  ];
  
  useEffect(() => {
    if (!isAdding) {
      // alert(isAdding)
      refetch();
    }
  }, [isAdding]);

  const handleDelete = row => {
    // setSelectedRow(row);

    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(result => {
      if (result.value) {
        // const [employee] = employees.filter(employee => employee.id === id);
        generalApi.general().deleteMassMail(row._id)
        .then(res => {
          
          if (res.data.success) {
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: `${row.name}'s data has been deleted.`,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            console.log(res.data.error);
            
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Failed to delete.',
              showConfirmButton: true,
            });
          }

          refetch();
          
        })
        .catch(err => {
          
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Failed to delete.',
            showConfirmButton: true,
          });

          refetch();
        });

      }
    });
  };

  return (
    <Box p="1.5rem 2.5rem" width={"100%"}>      
      {
        isAdding 
        ? 
        (<Add
          setIsAdding={setIsAdding}
        />) 
        :
        (<Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="60px"
          gap="20px"
          sx={{
            "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
          }}
        >
          <Box
            gridColumn="span 12"
            gridRow="span 2"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            <Header title="New Mass Mail" subTitle="" />
            <Box p="1rem" style={{display: "flex", justifyContent:"end"}}>
              <Button id="submit" variant="contained" onClick={()=>setIsAdding(true)}>Compose</Button>
            </Box>
          </Box>
        </Box>)
      }
      <Box
        mt="40px"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data || []}
          columns={merchantTableColumns}
          components={{Toolbar: DataGridCustomToolbarForMerchants}}
          // componentsProps={{
          //   toolbar: {setIsAdding}
          // }}
        />
      </Box>    
    </Box>
  );
};

export default MassMails;
