import React, { useState, useContext, useEffect } from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  Search,
  SettingsOutlined,
  ChevronRightOutlined,
  ArrowDropDownOutlined,
} from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDispatch } from "react-redux";
import { setMode } from "state";
import { useLocation, useNavigate  } from "react-router-dom";
// import profileImage from "assets/profile.jpeg";
import {
  AppBar,
  Button,
  Box,
  Typography,
  IconButton,
  InputBase,
  Toolbar,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import profileImage from "assets/avatar1.png";
import { capitalizeRole } from "utilities/CommonUtility";
import { AuthContext } from "../context/AuthContext";

const Navbar = ({ user }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const { logout, getAuthUser} = useContext(AuthContext);
  const authUser = getAuthUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleLogOut = () => {
    setAnchorEl(null);
    logout();
    navigate("/home");
  }

  const handleClickLogIn = () => {
    
    navigate("/login");
  }


  let navItems = [
    {
    text: "Home",
    },
    {
      text: "Downloads",
    },
    {
      text: "Contacts",
    },
    {
      text: "About",
    },
  ];
  if (user.role === "admin") {
    navItems = [
      {
        text: "Home",
      },
      {
        text: "Dashboard",
      },
      {
        text: "Users",
      },
      {
        text: "MassMails",
      },
      // {
      //   text: "Messages",
      // },
    ];
  }

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const handleClose = () => {
    setAnchorEl(null);
  }

  // console.log('uuserr', user);

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none", 
        display: "flex",
        justifyContent: "center",
        backgroundColor: theme.palette.primary[600]
        // padding: '0 0.5rem'
        // width: "80%",
        // minWidth: "400px"
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
         <List sx={{display: "flex"}}>
            {navItems.map(({ text }) => {
              
              const lcText = text.toLowerCase().replaceAll(' ', '_');
              // console.log('lcText', lcText);
              return (
                <ListItem key={text} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(`/${lcText}`);
                      setActive(lcText);
                    }}
                    sx={{
                      backgroundColor:
                        active === lcText
                          ? theme.palette.primary[400]
                          : "transparent",
                      color:
                        active === lcText
                          ? theme.palette.secondary[600]
                          : theme.palette.secondary[100],
                    }}
                  >
                    <ListItemText primary={text} />
                    {/* {active === lcText && (
                      <ChevronRightOutlined sx={{ ml: "auto" }} />
                    )} */}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          {/* <IconButton onClick={() => dispatch(setMode())}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlined sx={{ fontSize: "25px" }} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px" }} />
            )}
          </IconButton> */}
          {  authUser &&
            <FlexBetween>
              <Button
                onClick={handleClick}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textTransform: "none",
                  gap: "1rem",
                }}
              >
                <Box
                  component="img"
                  alt="profile"
                  src={profileImage}
                  height="32px"
                  width="32px"
                  borderRadius="50%"
                  sx={{ objectFit: "cover" }}
                />
                <Box textAlign="left">
                  <Typography
                    fontWeight="bold"
                    fontSize="0.85rem"
                    sx={{ color: theme.palette.secondary[100] }}
                  >
                    {user.name}
                  </Typography>
                  <Typography
                    fontSize="0.75rem"
                    sx={{ color: theme.palette.secondary[200] }}
                  >
                    {capitalizeRole(user.role)}
                  </Typography>
                </Box>
                <ArrowDropDownOutlined
                  sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
                />
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
              </Menu>
            </FlexBetween>
          }
            {/* <Button
              onClick={handleClickLogIn}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
                color: theme.palette.secondary[100],
              }}
            >
              <MenuItem sx={{backgroundColor: theme.palette.primary[900]}}>Log In</MenuItem>
            </Button> */}
          
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;