import React, { useEffect, useRef } from 'react';

import axios from "axios";

const PayPalButton = ({ total, resultMessage }) => {
    const paypalRef = useRef();

    useEffect(() => {
        window.paypal.Buttons({
            async createOrder() {
                try {
                    const response = await axios({
                        method: "POST",
                        url: process.env.REACT_APP_BASE_URL + "/payment/orders",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: {
                            amount: total,
                            currency: "USD",
                        },
                    });
          
                    const orderData = response.data;
                    // console.log('createOrder', orderData);
            
                    if (orderData.id) {
                        return orderData.id;
                    } else {
                        const errorDetail = orderData?.details?.[0];
                        const errorMessage = errorDetail
                        ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                        : JSON.stringify(orderData);
            
                        throw new Error(errorMessage);
                    }
                } catch (error) {
                    console.error(error);
                    resultMessage(`Could not initiate PayPal Checkout... ${error}`, false);
                }
            },
            async onApprove(data, actions) {
                try {
                    const response = await axios({
                        method: "POST",
                        url: process.env.REACT_APP_BASE_URL + `/payment/orders/${data.orderID}/capture`,
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });
          
                    const orderData = await response.data;
                    // console.log('onApprove', orderData);
                    // Three cases to handle:
                    //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                    //   (2) Other non-recoverable errors -> Show a failure message
                    //   (3) Successful transaction -> Show confirmation or thank you message
          
                    const errorDetail = orderData?.details?.[0];
          
                    if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                        // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                        // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                        return actions.restart();
                    } else if (errorDetail) {
                        // (2) Other non-recoverable errors -> Show a failure message
                        throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
                    } else if (!orderData.purchase_units) {
                        throw new Error(JSON.stringify(orderData));
                    } else {
                        // (3) Successful transaction -> Show confirmation or thank you message
                        // Or go to another URL:  actions.redirect('thank_you.html');
                        const transaction =
                            orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                            orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
                        resultMessage(
                        `Transaction ${transaction.status}: ${transaction.id}`, true
                        );
                        // console.log(
                        //     "Capture result",
                        //     orderData,
                        //     JSON.stringify(orderData, null, 2),
                        // );
                    }
                } catch (error) {
                    console.error(error);
                    resultMessage(
                        `Sorry, your transaction could not be processed... ${error}`, false
                    );
                }
            },
        }).render(paypalRef.current);
    }, [total]);

    // const resultMessage = (message) => {
    //     console.log('result-message', message);
    // }
    
    return (
        <div style={{width: "70%"}} ref={paypalRef}></div>
    );
}
 
export default PayPalButton;